import * as Sentry from "@sentry/gatsby";
const envs = { 
  staging: 'staging',
  production: 'production'
};

function getEnvironment() {
  if (typeof window === 'undefined') {
    return null;
  }

  const hostname = window.location?.hostname;
  if (/squareup\.com/.test(hostname)) {
    return envs.production;
  } else if (/squareupstaging\.com/.test(hostname)) {
    return envs.staging;
  }
    
  return null;
}

Sentry.init({
  dsn: "https://50b6f4435e6f49a5908491bb017edfed@o160250.ingest.sentry.io/6241827",
  environment: getEnvironment() || undefined,
  enabled: Object.values(envs).includes(getEnvironment()),
});